<template>
  <div>
    <div class="extend">
      <div class="inner">
        <div class="left-wrapper">
          <div class="title">Fintech Extend Your Market Reach</div>
          <div class="content margin-top-15">Fintech companies like payment gateways and fraud services are in a hyper-competitive space. It’s vital that they differentiate to attract and retain customers who increasingly demand a flexible approach to their payments strategy.</div>
        </div>
        <img src="../../assets/images/use_cases_fintech/p1.png" />
      </div>
    </div>
    <div class="orchestration">
      <div class="inner">
        <div class="title">
          <div>With our Payment Orchestration solution, you can:</div>
        </div>
        <div class="content-list">
          <template v-for="(item, index) in solutions">
            <div :key="index" class="content-item margin-top-50">
              <img class="icon" :src="item.icon" />
              <div class="content">{{item.content}}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="acquire">
      <div class="inner">
        <div class="title">
          <div>Acquire New Merchants Globally</div>
        </div>
        <div class="content">
          <div>Your business growth depends on adding new merchants and platforms to your service. But competitive pressures mean you need to constantly enhance the value of your service to differentiate.</div>
          <div class="margin-top-20">Moozumi helps fintechs around the globe via access to our ecosystem of payment services. That allows our fintech customers to build out a more comprehensive solution for prospects:</div>
        </div>
        <div class="list-items">
          <template v-for="(item, index) in acquireList">
            <div :key="index" class="item">
              <img src="../../assets/images/use_cases_fintech/icon.png" />
              <div class="text">{{item}}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="effect">
      <div class="grow">
        <div class="content-wrapper">
          <div class="title">
            <div>Retain Your Existing Customers</div>
          </div>
          <div class="content">
            <div>As the advantages become clear, the market trend toward a multi-provider payment strategy continues to accelerate. That means your merchant and platform customers expect the ability to connect to multiple services. Or, they’ll choose another vendor.</div>
            <div class="margin-top-20">Moozumi’s flexible payment infrastructure lets you provide a range of services to your existing customers. That gives them the control they need while retaining them as profitable customers. </div>
          </div>
        </div>
        <img src="../../assets/images/use_cases_fintech/p2.png" />
      </div>
      <div class="optimize">
        <img src="../../assets/images/use_cases_fintech/p3.png" />
        <div class="content-wrapper">
          <div class="title">
            <div>Extend Your Value with a World of Payment Services</div>
          </div>
          <div class="content">
            <div>Even the largest payment service providers don’t have a complete range of capabilities. To support growth and fend off competitors, payment services must leverage peer services in related markets.</div>
            <div class="margin-top-20">Moozumi’s ecosystem approach and robust platform enable fintech customers to access a range of functionality. </div>
          </div>
        </div>
      </div>
    </div>
    <MoozumiEmail></MoozumiEmail>
  </div>
</template>
<script>
import MoozumiEmail from '../../components/MoozumiEmail.vue'

export default {
  components: { MoozumiEmail },
  data () {
    return {
      solutions: [
        {
          icon: require('../../assets/images/use_cases_fintech/icon1.png'),
          content: 'Acquire new customers by supporting successful transactions in the markets they demand'
        },
        {
          icon: require('../../assets/images/use_cases_fintech/icon2.png'),
          content: 'Retain existing customers by increasing flexibility in their payments stack via Payment Orchestration'
        },
        {
          icon: require('../../assets/images/use_cases_fintech/icon3.png'),
          content: 'Offer value-added services to customers with Account Updater, network tokenization, smart routing, fraud orchestration, and more '
        }
      ],
      acquireList: [
        'Tap into new geographies by offering customers connectivity to a world of payment services',
        'Differentiate from competitors by providing a flexible approach, versus an incomplete solution',
        'Partner with the leader by working with our established platform and deep industry relationships'
      ]
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../styles/variables.scss';

.extend {
  background: url(../../assets/images/use_cases_fintech/bg1.png);
  background-size: cover;
  padding: 77px 0 56px;
  .inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .left-wrapper {
      width: 500px;
      max-width: 100%;
      .title {
        font-family: Helvetica-Bold;
        font-size: 52px;
        color: $primary-color1;
        letter-spacing: 0;
        text-align: left;
        line-height: 68px;
      }
      .content {
        font-family: HelveticaNeue;
        font-size: 18px;
        color: $content-color;
        letter-spacing: 0;
        text-align: left;
        line-height: 36px;
      }
    }
    img {
      width: 690px;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}
.orchestration {
  padding: 120px 0;
  background: url(../../assets/images/use_cases_fintech/bg2.png);
  background-size: cover;
  .inner {
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    .title {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: $content-color;
      letter-spacing: 0;
      text-align: left;
      line-height: 52px;
      position: relative;
      &:before {
        content: '';
        width: 140px;
        height: 6px;
        display: block;
        background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
        border-radius: 5px;
        margin-bottom: 50px;
      }
    }
    .content-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .content-item {
        width: 330px;
        box-shadow: 0 0 77px 0 rgb(0 0 0 / 6%);
        background: $white;
        border-radius: 20px;
        text-align: left;
        padding: 32px 24px;
        .icon {
          width: 60px;
        }
        .content {
          font-family: HelveticaNeue;
          font-size: 20px;
          color: $nav-title-color;
          letter-spacing: 0;
          text-align: left;
          line-height: 36px;
          margin-top: 24px;
        }
      }
    }
  }
}
.acquire {
  padding: 120px 0;
  background: url(../../assets/images/use_cases_fintech/bg3.png);
  background-size: cover;
  .inner {
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    .title {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: left;
      line-height: 52px;
      position: relative;
      &:before {
        content: '';
        width: 140px;
        height: 6px;
        display: block;
        background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
        border-radius: 5px;
        margin-bottom: 50px;
      }
    }
    .content {
      margin-top: 15px;
      font-family: HelveticaNeue;
      font-size: 20px;
      color: $white;
      letter-spacing: 0;
      text-align: left;
      line-height: 36px;
    }
    .list-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        border-left: 1px solid #2A6095;
        width: 350px;
        margin-top: 50px;
        font-family: HelveticaNeue;
        font-size: 20px;
        color: $white;
        letter-spacing: 0;
        text-align: left;
        line-height: 32px;
        display: flex;
        justify-content: space-around;
        img {
          width: 19px;
          height: 19px;
        }
        .text {
          width: 300px;
          margin-top: -6px;
        }
      }
    }
  }
}
.effect {
  padding: 60px 0;
  background: url(../../assets/images/use_cases_merchant/bg4.png);
  background-size: cover;
  .grow, .optimize {
    padding: 60px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    img {
      width: 462px;
      max-width: 100%;
      box-sizing: border-box;
    }
    .title {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: $content-color;
      letter-spacing: 0;
      text-align: left;
      line-height: 52px;
      position: relative;
      &:before {
        content: '';
        width: 140px;
        height: 6px;
        display: block;
        background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
        border-radius: 5px;
        margin-bottom: 50px;
      }
    }
    .content-wrapper {
      width: 602px;
      max-width: 100%;
      box-sizing: border-box;
      .content {
        font-family: HelveticaNeue;
        font-size: 20px;
        color: $primary-color;
        letter-spacing: 0;
        text-align: left;
        line-height: 36px;
        margin-top: 16px;
      }
    }
  }
  .optimize {
    align-items: center;
  }
}
@media screen and (max-width: 1200px) {
  .extend, .orchestration, .acquire, .effect {
    .inner {
      padding: 0 15px;
    }
  }
}
</style>
